<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="social_footer">
          <p>© Bäckerei Reider 2020</p>
        </div>
      </div>
    </div>
    <!-- End row -->
  </div>
  <!-- End container -->
</footer>
