
  <div class="row">
    <div class="col-md-offset-3 col-md-6">
      <div class="box_style_2">
        <div id="confirm">
          <i class="icon_check_alt2"></i>
          <h3>Vielen Dank!</h3>
          <p>
            Eine Best&auml;tigung Ihres Auftrags mit den einzelnen Positionen erhalten Sie an Ihre registrierte E-Mail Adresse zugesandt. Wir bedanken uns vielmals f&uuml;r Ihre Bestellung und f&uuml;r Ihr Vertrauen in unsere Produkte.
          </p>
          <p>
            Geliefert wird im Verlaufe des Vormittags bis ca. 14:00 Uhr!
          </p>
          <p>
            Ihr B&auml;ckerei Reider Team
          </p>
        </div>
      </div>
      <div>
        <button class="btn btn_full_outline" (click)="onBackToShop()">Zur&uuml;ck zu den Produkten</button>
      </div>
    </div>
  </div>
