import {ProductCategory} from '../models/product-category.model';
import {Subject} from 'rxjs/Subject';
import {Headers, Http, Response} from '@angular/http';
import {AuthService} from '../../auth/auth.service';
import {Injectable, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class ProductCategoryService {

  categoriesChanged = new Subject<ProductCategory[]>();
  categories: ProductCategory[] = [];
  categorySelected = new Subject<string>();

  getCategories() {
    return this.categories.slice();
  }

  constructor(private http: Http, private authService: AuthService) {

    this.loadCategories();
  }

  getCategory(id: string) {
    for (let category of this.categories) {
      if (category.id === id) {
        return category;
      }
    }
  }

  loadCategories() {
    this.http.get('https://' + environment.firebase.projectId + '.firebaseio.com/product-categories.json')
      .map(
        (response: Response) => {
          const categories = response.json();
          return categories;
        }
      )
      .subscribe(
        (categories: ProductCategory[]) => {
          this.categories = categories;
          if (!this.categories) {
            this.categories = [];
          }
          this.categoriesChanged.next(this.categories.slice());
        }
      );
  }

  persistCategories() {
    // const headers = new Headers({'Content-Type': 'application/json'});
    // console.log('persistcategories');
    // // const token = this.authService.getToken();
    // // return this.http.put('https://reider-dev.firebaseio.com/test.json?auth=' + token, this.getCategories());
    // return this.http.put('https://reider-dev.firebaseio.com/product-categories.json', this.getCategories(), {headers: headers})
    //   .subscribe(
    //     (response) => console.log(response),
    //     (error) => console.log(error)
    //   );
  }

  addCategory(category: ProductCategory) {
    this.categories.push(category);
    this.persistCategories();
    this.categoriesChanged.next(this.categories.slice());
  }

  updateCategory(id: string, newCategory: ProductCategory) {
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].id === id) {
        this.categories[i] = newCategory;
      }
    }
    this.persistCategories();
    this.categoriesChanged.next(this.categories.slice());
  }

  deleteCategory(id: string) {
    let removeIndex;
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].id === id) {
        removeIndex = i;
      }
    }
    if (removeIndex) {
      this.categories.splice(removeIndex, 1);
    }
    this.persistCategories();
    this.categoriesChanged.next(this.categories.slice());
  }

}
