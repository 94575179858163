import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-list-tools',
  templateUrl: './product-list-tools.component.html',
  styleUrls: ['./product-list-tools.component.css']
})
export class ProductListToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
