<div class="strip_list wow fadeIn" data-wow-delay="0.1s">
  <!--<div class="ribbon_1">-->
  <!--</div>-->
  <div class="row">
    <div class="col-xs-12 col-md-4 col-sm-4">
      <div class="desc">
        <div class="thumb_strip">
          <img [src]="product.imagePath" *ngIf="product.imagePath">
          <img src="https://firebasestorage.googleapis.com/v0/b/reider-app.appspot.com/o/commons%2Fno_product_image.png?alt=media&token=9c2d5ad0-9e90-47d8-a85d-230530028941" *ngIf="!product.imagePath" />
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-lg-8 col-md-8">
      <div>
        <h4>{{product.name}} <i class="pull-right">{{product.price | euro}}</i></h4>
        <div class="type">
          {{product.weightGrams | weight_gram}} {{product.description}}
        </div>
        <div></div>
      </div>
      <div *ngIf="product.available && !product.onRequest">
          <a class="btn_full cart_action" (click)="onAddToCart()" *ngIf="isAuthenticated()">In den Warenkorb</a>
      </div>
      <div class="cart_action pull-right" *ngIf="!product.available">
        <i>Produkt zur Zeit nicht verf&uuml;gbar</i>
      </div>
      <div class="cart_action pull-right" *ngIf="product.onRequest">
        <i>auf Anfrage</i>
      </div>
    </div>
  </div><!-- End row-->
</div><!-- End strip_list -->
