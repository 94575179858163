import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {HolidayService} from '../../../shared/services/holiday.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import * as uuid from 'uuid';
import {Subscription} from 'rxjs/Subscription';
import {Holiday} from '../../../shared/models/holiday.model';

@Component({
  selector: 'app-admin-holiday-detail',
  templateUrl: './admin-holiday-detail.component.html',
  styleUrls: ['./admin-holiday-detail.component.css']
})
export class AdminHolidayDetailComponent implements OnInit, OnDestroy {

  private holidaySubscription: Subscription;

  index: string;
  holidays: Holiday[];
  holidayForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private holidayService: HolidayService,
              private router: Router) {
  }

  ngOnInit() {
    this.holidaySubscription = this.holidayService.holidaysChanged.subscribe(
      (holidays: Holiday[]) => {
        this.holidays = holidays;
      }
    );
    this.holidays = this.holidayService.getHolidays();

    this.route.params.subscribe(
      (params: Params) => {
        this.index = params['id'];
        this.initForm(); // refresh form content
      }
    );
    this.initForm(); // refresh form content
  }

  ngOnDestroy() {
    this.holidaySubscription.unsubscribe();
  }

  onSubmit() {
    if (this.index) {
      this.holidayService.updateHoliday(this.holidays[this.index].id, this.holidayForm.value); // because form value has the same form as recipe
    } else {
      this.holidayService.addHoliday(this.holidayForm.value);
    }
    this.onCancel();
  }

  private initForm() {
    let id = '';
    let name = '';
    let from = '';
    let to = '';
    let displayText = '';
    let displayTextFrom = '';

    if (this.index) {
      const holiday = this.holidays[this.index];
      id = holiday.id;
      name = holiday.name;
      from = holiday.from;
      to = holiday.to;
      displayText = holiday.displayText;
      displayTextFrom = holiday.displayTextFrom;
    } else {
      id = uuid.v4();
    }

    this.holidayForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'from': new FormControl(from, Validators.required),
      'to': new FormControl(to, Validators.required),
      'displayText': new FormControl(displayText),
      'displayTextFrom': new FormControl(displayTextFrom)
    });
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}
