<div class="row">
  <div class="col-xs-12">
    <section id="section-2">
      <div class="indent_title_in">
        <h3>Produkte</h3>
      </div>

      <div class="row">
        <div class="col-md-8">
          <app-admin-product-list></app-admin-product-list>
        </div>
        <div class="col-md-4">
          <router-outlet></router-outlet>
        </div>
      </div>
    </section><!-- End section 2 -->
  </div>
</div>
