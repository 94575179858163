import * as firebase from 'firebase';
import {ActivatedRoute,  Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {User} from '../shared/models/user.model';
import {UserService} from '../shared/services/user.service';
import {MailService} from '../shared/services/mail.service';
import {Subject} from 'rxjs/Subject';
import DataSnapshot = firebase.database.DataSnapshot;

@Injectable()
export class AuthService {

  loginStateChanged = new Subject();
  activeUserChanged = new Subject<{ user: User }>();
  token: string;
  userId: string;
  activeUser: User;
  errorCode: string;
  loggedIn: boolean = false;

  constructor(private router: Router,
              private userService: UserService,
              private route: ActivatedRoute,
              private mailService: MailService) {

  }

  signinUser(email: string, password: string) {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(value => {

          firebase.auth().onAuthStateChanged(
            (user) => {
              if (user) {
                this.loggedIn = true;
                firebase.database().ref('users').orderByChild('userid').equalTo(user.uid).once('value')
                  .then((snapshot: DataSnapshot) => {
                    snapshot.forEach((childSnapshot) => {
                        this.activeUser = childSnapshot.val();
                        this.activeUserChanged.next({user: this.activeUser});
                        //this.router.navigate(['/']);
                        return false;
                      }
                    );
                  });
              } else {
                this.loggedIn =false;
              }
            }
          );


          firebase.auth().signInWithEmailAndPassword(email, password)
            .then(
              response => {
                this.userId = response.user.uid;
                firebase.auth().currentUser.getIdToken()
                  .then(
                    (token: string) => {
                      this.token = token;
                      // localStorage.setItem('brapptoken', this.token);
                    }
                  );
                this.loginStateChanged.next();
              }
            )
            .catch(
              (error) => {
                this.errorCode = error.code;
                this.loginStateChanged.next();
              }
            );
        }
      );
  }

  signoutUser() {
    firebase.auth().signOut();
    this.token = null;
  }

  isAuthenticated() {
    if (this.token == null) {
      this.reloadAuthState();
    }
    return this.token != null;
  }

  signupUser(user: User) {
    const password = this.generatePassword();
    firebase.auth().createUserWithEmailAndPassword(user.email, password)
      .then(
        (result) => {
          user.userid = result.user.uid;

          const authUser = firebase.auth().currentUser;
          authUser.updateProfile({
            displayName: user.firstname + ' ' + user.name,
            photoURL: ''
          });

          user.status = 'TEMPPW';
          firebase.database().ref('users/' + user.id).update(user).catch(error => console.log(error));
          this.mailService.sendEmailWelcome(user, password);
        }
      )
      .catch(
        error => console.log(error)
      );
  }

  private generatePassword() {
    return Math.random().toString(36).slice(-8);
  }

  getToken() {
    firebase.auth().currentUser.getIdToken()
      .then(
        (token: string) => this.token = token
      );
    return this.token;
  }

  resetPassword(email: string) {
    firebase.auth().sendPasswordResetEmail(email).then(function () {
      // Email sent.
    }).catch(function (error) {
      // error
    });
    return false;
  }

  reloadAuthState() {
    if (firebase.auth().currentUser != null && this.loggedIn) {
     //console.log("reload auth state");
     //firebase.auth().currentUser.getIdToken()
     //  .then(
     //    (token: string) => {
     //      this.token = token;
     //        firebase.database().ref('users').orderByChild('userid').equalTo(firebase.auth().currentUser.uid).once('value')
     //          .then((snapshot: DataSnapshot) => {
     //            snapshot.forEach((childSnapshot) => {
     //                this.activeUser = childSnapshot.val();
     //                this.activeUserChanged.next({user: this.activeUser});
     //                return false;
     //              }
     //            );
     //          });
     //    }
     //  );
      this.loginStateChanged.next();
    }
  }

  isAdmin() {
    return this.token != null && this.activeUser != null && (
      this.activeUser.email === 'silvan.krebs@gmail.com' || this.activeUser.email === 'chrebsli@gmail.com'
    );
  }
}
