<div class="row">
  <div class="col-xs-12">
    <div *ngIf="openUserRegs.length < 1"><i><strong style="color: green;" >Alles OK</strong> - Keine User Registrierungen offen.</i></div>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let userReg of openUserRegs; let i = index">
        <div class="row">
          <div class="col-md-8 col-xs-12">
            <h5>{{userReg.name}} {{userReg.firstname}}, {{userReg.street}}, {{userReg.zip}} {{userReg.city}}</h5>
          </div>
          <div class="col-md-2 col-xs-6">
            <button type="button" (click)="onActivateUser(i)" class="btn btn-success">Aktivieren</button>
          </div>
          <div class="col-md-2 col-xs-6">
            <button type="button" (click)="onDeclineUser(i)" class="btn btn-danger">X</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
