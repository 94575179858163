<div class="container margin_60">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="content">
        <section id="section-3">
          <div class="col-md-12 col-sm-12 add_bottom_15">
            <div class="indent_title_in">
              <i class="icon_lock_alt"></i>
              <h3>Passwort ändern</h3>
            </div>
            <p>
              Bitte ändern Sie Ihr temporäres Passwort!
            </p>
            <div class="wrapper_indent">
              <div class="form-group">
                <label>Bisheriges Passwort</label>
                <input class="form-control" name="old_password" id="old_password" type="password">
              </div>
              <div class="form-group">
                <label>Neues Passwort</label>
                <input class="form-control" name="new_password" id="new_password" type="password">
              </div>
              <div class="form-group">
                <label>Neues Passwort (wiederholen)</label>
                <input class="form-control" name="new_password_rep" id="new_password_rep" type="password">
              </div>
              <button type="submit" class="btn_full">Passwort ändern</button>
            </div>
          </div>
        </section>
      </div>

    </div>
  </div>
</div>
