import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './signup-confirm.component.html',
  styleUrls: ['./signup-confirm.component.css']
})
export class SignupConfirmComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onBackToShop() {
    this.router.navigate(['/shop']);
  }

}
