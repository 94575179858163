<div class="box_style_2" id="main_menu">
  <h2 class="inner">Datenschutzerkl&auml;rung</h2>
  <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
    ist:</p><br/>
  <p>B&auml;ckerei Klaus und Andrea Reider<br/>Klaus und Andrea Reider<br/>Weyreggerstrasse 73<br/>4852 Weyregg</p>
  <p>Telefon: 07664 / 2229<br/>E-Mail: baeckereireider.at@gmail.com<br/>WebSite: https://www.baeckerei-reider.at/ </p>
  <p>&nbsp;</p>
  <h2 class="inner">Allgemeiner Hinweis</h2>
  <p>Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen des
    Bundes (Datenschutzgesetz, DSG<!--DSG--> ) hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf
    Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
    Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
    Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p>
  <p>In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die Datenbanken so gut wie m&ouml;glich vor
    fremden Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.</p>
  <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail)
    Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
    m&ouml;glich.</p>
  <p>Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss
    der nachfolgenden Beschreibung einverstanden. Diese Website kann grunds&auml;tzlich ohne Registrierung besucht
    werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit
    zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen
    werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit m&ouml;glich auf
    freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Bearbeitung von Personendaten</h2>
  <p>Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine betroffene
    Person ist eine Person, &uuml;ber die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit
    Personendaten, unabh&auml;ngig von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren,
    Bekanntgeben, Beschaffen, L&ouml;schen, Speichern, Ver&auml;ndern, Vernichten und Verwenden von Personendaten.</p>
  <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht. Im &Uuml;brigen bearbeiten wir –
    soweit und sofern die EU-DSGVO anwendbar ist – Personendaten gem&auml;ss folgenden Rechtsgrundlagen im Zusammenhang
    mit Art. 6 Abs. 1 DSGVO<!--DSGVO--> :</p>
  <ul>
    <li>lit. a) Bearbeitung von Personendaten mit Einwilligung der betroffenen Person.</li>
    <li>lit. b) Bearbeitung von Personendaten zur Erf&uuml;llung eines Vertrages mit der betroffenen Person sowie zur
      Durchf&uuml;hrung entsprechender vorvertraglicher Massnahmen.
    </li>
    <li>lit. c) Bearbeitung von Personendaten zur Erf&uuml;llung einer rechtlichen Verpflichtung, der wir gem&auml;ss
      allenfalls anwendbarem Recht der EU oder gem&auml;ss allenfalls anwendbarem Recht eines Landes, in dem die DSGVO
      <!--DSGVO--> ganz oder teilweise anwendbar ist, unterliegen.
    </li>
    <li>lit. d) Bearbeitung von Personendaten um lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen
      Person zu sch&uuml;tzen.
    </li>
    <li>lit. f) Bearbeitung von Personendaten um die berechtigten Interessen von uns oder von Dritten zu wahren, sofern
      nicht die Grundfreiheiten und Grundrechte sowie Interessen der betroffenen Person &uuml;berwiegen. Berechtigte
      Interessen sind insbesondere unser betriebswirtschaftliches Interesse, unsere Website bereitstellen zu k&ouml;nnen,
      die Informationssicherheit, die Durchsetzung von eigenen rechtlichen Anspr&uuml;chen und die Einhaltung von
      schweizerischem Recht.
    </li>
  </ul>
  <p>Wir bearbeiten Personendaten f&uuml;r jene Dauer, die f&uuml;r den jeweiligen Zweck oder die jeweiligen Zwecke
    erforderlich ist. Bei l&auml;nger dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und sonstigen
    Pflichten, denen wir unterliegen, schr&auml;nken wir die Bearbeitung entsprechend ein.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Datenschutzerkl&auml;rung f&uuml;r Cookies</h2>
  <p>Diese Website verwendet Cookies. Das sind kleine Textdateien, die es m&ouml;glich machen, auf dem Endger&auml;t des
    Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, w&auml;hrend er die Website nutzt. Cookies
    erm&ouml;glichen es, insbesondere Nutzungsh&auml;ufigkeit und Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen
    der Seitennutzung zu analysieren, aber auch unser Angebot kundenfreundlicher zu gestalten. <!--Allg.Cookies-->
    Cookies bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und k&ouml;nnen bei einem erneuten Seitenbesuch
    wieder aufgerufen werden. Wenn Sie das nicht w&uuml;nschen, sollten Sie Ihren Internetbrowser so einstellen, dass er
    die Annahme von Cookies verweigert.</p>
  <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer
    Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a
      href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a> oder
    die EU-Seite <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener">http://www.youronlinechoices.com/</a>
    erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des
    Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
    genutzt werden können.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
  <p>Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie
    zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine
    verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf
    &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
  <p>Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln,
    nicht von Dritten mitgelesen werden.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Datenschutzerkl&auml;rung f&uuml;r Server-Log-Files</h2>
  <p>Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die
    Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
  <ul>
    <li>Browsertyp und Browserversion</li>
    <li>verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Uhrzeit der Serveranfrage</li>
  </ul>
  <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
    Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zupr&uuml;fen, wenn uns
    konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Dienste von Dritten</h2>
  <p>Diese Website verwenden allenfalls Google Maps f&uuml;r das Einbetten von Karten, Google Invisible reCAPTCHA f&uuml;r
    den Schutz gegen Bots und Spam sowie YouTube f&uuml;r das Einbetten von Videos.</p>
  <p>Diese Dienste der amerikanischen Google LLC verwenden unter anderem Cookies und infolgedessen werden Daten an
    Google in den USA &uuml;bertragen, wobei wir davon ausgehen, dass in diesem Rahmen kein personenbezogenes Tracking
    allein durch die Nutzung unserer Website stattfindet.</p>
  <p>Google hat sich verpflichtet, einen angemessenen Datenschutz gem&auml;ss dem amerikanisch-europ&auml;ischen und dem
    amerikanisch-schweizerischen Privacy Shield zu gew&auml;hrleisten.</p>
  <p>Weitere Informationen finden sich in der <a href='https://policies.google.com/privacy?hl=de' target="_blank"
                                                 rel="noopener" style="color: inherit;">Datenschutzerkl&auml;rung von
    Google</a>.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Rechte betroffener Personen</h2>
  <p><strong>Recht auf Best&auml;tigung</strong></p>
  <p>Jede betroffene Person hat das Recht, vom Betreiber der Website eine Best&auml;tigung dar&uuml;ber zu verlangen, ob
    betroffene Personen betreffende, personenbezogene Daten verarbeitet werden. M&ouml;chten Sie dieses Best&auml;tigungsrecht
    in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an den Datenschutzbeauftragten wenden.</p> <br/>
  <p><strong>Recht auf Auskunft</strong></p>
  <p>Jede von der Verarbeitung betroffene Person mit personenbezogenen Daten hat das Recht, jederzeit vom Betreiber
    dieser Website unentgeltliche Auskunft &uuml;ber die zu seiner Person gespeicherten personenbezogenen Daten und eine
    Kopie dieser Auskunft zu erhalten. Ferner kann gegebenenfalls &uuml;ber folgende Informationen Auskunft gegeben
    werden:</p>
  <ul>
    <li>die Verarbeitungszwecke</li>
    <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
    <li>die Empf&auml;nger, gegen&uuml;ber denen die personenbezogenen Daten offengelegt worden sind oder noch
      offengelegt werden
    </li>
    <li>falls m&ouml;glich, die geplante Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden, oder, falls
      dies nicht m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung dieser Dauer
    </li>
    <li>das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der sie betreffenden personenbezogenen Daten oder
      auf Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese
      Verarbeitung
    </li>
    <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde</li>
    <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verf&uuml;gbaren
      Informationen &uuml;ber die Herkunft der Daten
    </li>
  </ul>
  <p>Ferner steht der betroffenen Person ein Auskunftsrecht dar&uuml;ber zu, ob personenbezogene Daten an ein Drittland
    oder an eine internationale Organisation &uuml;bermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen
    Person im &uuml;brigen das Recht zu, Auskunft &uuml;ber die geeigneten Garantien im Zusammenhang mit der &uuml;bermittlung
    zu erhalten.</p>
  <p>M&ouml;chten Sie dieses Auskunftsrecht in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an unseren
    Datenschutzbeauftragten wenden.</p> <br/>
  <p><strong>Recht auf Berichtigung</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverz&uuml;gliche
    Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person
    das Recht zu, unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung, die Vervollst&auml;ndigung unvollst&auml;ndiger
    personenbezogener Daten - auch mittels einer erg&auml;nzenden Erkl&auml;rung - zu verlangen.</p>
  <p>M&ouml;chten Sie dieses Berichtigungsrecht in Anspruch nehmen, k&ouml;nnen Sie sich hierzu jederzeit an unseren
    Datenschutzbeauftragten wenden.</p> <br/>
  <p><strong>Recht auf L&ouml;schung (Recht auf Vergessen werden)</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser
    Website zu verlangen, dass die sie betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht werden,
    sofern einer der folgenden Gr&uuml;nde zutrifft und soweit die Verarbeitung nicht erforderlich ist:</p>
  <ul>
    <li>Die personenbezogenen Daten wurden f&uuml;r solche Zwecke erhoben oder auf sonstige Weise verarbeitet, f&uuml;r
      welche sie nicht mehr notwendig sind
    </li>
    <li>Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung st&uuml;tzte, und es fehlt an
      einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung
    </li>
    <li>Die betroffene Person legt aus Gr&uuml;nden, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen
      die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder
      die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling Widerspruch gegen die
      Verarbeitung ein
    </li>
    <li>Die personenbezogenen Daten wurden unrechtm&auml;ssig verarbeitet</li>
    <li>Die L&ouml;schung der personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
      Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt
    </li>
    <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft, die einem Kind
      direkt gemacht wurden, erhoben
    </li>
  </ul>
  <p>Sofern einer der oben genannten Gr&uuml;nde zutrifft und Sie die L&ouml;schung von personenbezogenen Daten, die
    beimBetreiber dieser Website gespeichert sind, veranlassen m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
    unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird veranlassen, dass dem L&ouml;schverlangen
    unverz&uuml;glich nachgekommen wird.</p> <br/>
  <p><strong>Recht auf Einschr&auml;nkung der Verarbeitung</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser
    Website die Einschr&auml;nkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben
    ist:</p>
  <ul>
    <li>Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar f&uuml;r eine
      Dauer, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der personenbezogenen Daten zu &uuml;berpr&uuml;fen
    </li>
    <li>Die Verarbeitung ist unrechtm&auml;ssig, die betroffene Person lehnt die L&ouml;schung der personenbezogenen
      Daten ab und verlangt stattdessen die Einschr&auml;nkung der Nutzung der personenbezogenen Daten
    </li>
    <li>Der Verantwortliche ben&ouml;tigt die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung nicht l&auml;nger,
      die betroffene Person ben&ouml;tigt sie jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
    </li>
    <li>Die betroffene Person hat aus Gr&uuml;nden, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen
      die Verarbeitung eingelegt und es steht noch nicht fest, ob die berechtigten Gr&uuml;nde des Verantwortlichen
      gegen&uuml;ber denen der betroffenen Person &uuml;berwiegen
    </li>
  </ul>
  <p>Sofern eine der oben genannten Voraussetzungen gegeben ist Sie die Einschr&auml;nkung von personenbezogenen Daten,
    die beim Betreiber dieser Website gespeichert sind, verlangen m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
    unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird die Einschr&auml;nkung der
    Verarbeitung veranlassen.</p> <br/>
  <p><strong>Recht auf Daten&uuml;bertragbarkeit</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden
    personenbezogenen Daten in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Sie hat
    ausserdem das Recht, dass diese Daten bei Vorliegen der gesetzlichen Voraussetzungen einem anderen Verantwortlichen
    &uuml;bermittelt werden.</p>
  <p>Ferner hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
    Verantwortlichen an einen anderen Verantwortlichen &uuml;bermittelt werden, soweit dies technisch machbar ist und
    sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeintr&auml;chtigt werden.</p>
  <p>Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit k&ouml;nnen Sie sich jederzeit an den vom Betreiber
    dieser Website bestellten Datenschutzbeauftragten wenden.</p> <br/>
  <p><strong>Recht auf Widerspruch</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gr&uuml;nden, die sich aus
    ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten,
    Widerspruch einzulegen.</p>
  <p>Der Betreiber dieser Website verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
    denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die den
    Interessen, Rechten und Freiheiten der betroffenen Person &uuml;berwiegen, oder wenn die Verarbeitung der
    Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</p>
  <p>Zur Aus&uuml;bung des Rechts auf Widerspruch k&ouml;nnen Sie sich direkt an den Datenschutzbeauftragten dieser
    Website wenden.</p> <br/>
  <p><strong>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</strong></p>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine abgegebene Einwilligung zur
    Verarbeitung personenbezogener Daten jederzeit zu widerrufen.</p>
  <p>M&ouml;chten Sie Ihr Recht auf Widerruf einer Einwilligung geltend machen, k&ouml;nnen Sie sich hierzu jederzeit an
    unseren Datenschutzbeauftragten wenden.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Datenschutzerkl&auml;rung für die Nutzung von Google Web Fonts</h2>
  <p>Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
    bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren
    Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird
    eine Standardschrift von Ihrem Computer genutzt.</p>
  <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
                                                                   target="_blank" style="color: inherit;">https://developers.google.com/fonts/faq</a>
    und in der Datenschutzerkl&auml;rung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank"
                                                        style="color: inherit;">https://www.google.com/policies/privacy/</a>
  </p>
  <p>&nbsp;</p>
  <h2 class="inner">Datenschutzerkl&auml;rung f&uuml;r Facebook</h2>
  <p>Diese Website verwendet Funktionen von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA . Bei Aufruf
    unserer Seiten mit Facebook-Plug-Ins wird eine Verbindung zwischen Ihrem Browser und den Servern von Facebook
    aufgebaut. Dabei werden bereits Daten an Facebook &uuml;bertragen. Besitzen Sie einen Facebook-Account, k&ouml;nnen
    diese Daten damit verkn&uuml;pft werden. Wenn Sie keine Zuordnung dieser Daten zu Ihrem Facebook-Account w&uuml;nschen,
    loggen Sie sich bitte vor dem Besuch unserer Seite bei Facebook aus. Interaktionen, insbesondere das Nutzen einer
    Kommentarfunktion oder das Anklicken eines „Like“- oder „Teilen“-Buttons werden ebenfalls an Facebook weitergegeben.
    Mehr erfahren Sie unter <a href="https://de-de.facebook.com/about/privacy" target="_blank" style="color: inherit;">https://de-de.facebook.com/about/privacy</a>.
  </p>
  <p>&nbsp;</p>
  <h2 class="inner">Verwendung von Adobe Fonts</h2>
  <p>Wir setzen Adobe Fonts zur visuellen Gestaltung unserer Website ein. Adobe Fonts ist ein Dienst der Adobe Systems
    Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe), der uns den Zugriff auf eine
    Schriftartenbibliothek gew&auml;hrt. Zur Einbindung der von uns benutzten Schriftarten, muss Ihr Browser eine
    Verbindung zu einem Server von Adobe in den USA aufbauen und die f&uuml;r unsere Website ben&ouml;tigte Schriftart
    herunterladen. Adobe erh&auml;lt hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen
    wurde. Weitere Informationen zu Adobe Fonts, finden Sie in den Datenschutzhinweisen von Adobe, die Sie hier abrufen
    k&ouml;nnen: <a href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html" target="_blank" rel="noopener">Adobe
      Fonts</a></p>
  <p>&nbsp;</p>
  <h2 class="inner">Nutzung von Fonts.com</h2>
  <p>Diese Website nutzt Fonts.com, ein von Monotype Imaging Holdings Inc. bereitgestellter Service zur Visualisierung
    von Schriftarten, mit dem diese Website entsprechende Inhalte auf ihren Seiten einbinden kann. Erhobene
    personenbezogene Daten: Nutzungsdaten und verschiedene Datenarten, wie in der Datenschutzerklärung des Dienstes
    beschrieben. Verarbeitungsort: Vereinigte Staaten von Amerika (USA); <a
      href="http://www.monotype.com/legal/privacy-policy" rel="noopener" target="_blank">Datenschutzerklärung</a></p>
  <p>&nbsp;</p>
  <h2 class="inner">Urheberrechte</h2>
  <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website, geh&ouml;ren
    ausschliesslich dem Betreiber dieser Website oder den speziell genannten Rechteinhabern. Für die Reproduktion von
    sämtlichen Dateien, ist die schriftliche Zustimmung des Urheberrechtstr&auml;gers im Voraus einzuholen.</p>
  <p>Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich strafbar und
    allenfalls schadenersatzpflichtig machen.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Allgemeiner Haftungsausschluss</h2>
  <p>Alle Angaben unseres Internetangebotes wurden sorgf&auml;ltig gepr&uuml;ft. Wir bem&uuml;hen uns, unser
    Informationsangebot aktuell, inhaltlich richtig und vollst&auml;ndig anzubieten. Trotzdem kann das Auftreten von
    Fehlern nicht v&ouml;llig ausgeschlossen werden, womit wir keine Garantie f&uuml;r Vollst&auml;ndigkeit, Richtigkeit
    und Aktualit&auml;t von Informationen auch journalistisch-redaktioneller Art &uuml;bernehmen k&ouml;nnen.
    Haftungsanspr&uuml;che aus Sch&auml;den materieller oder ideeller Art, die durch die Nutzung der angebotenen
    Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vors&auml;tzliches oder grob fahrl&auml;ssiges
    Verschulden vorliegt.</p>
  <p>Der Herausgeber kann nach eigenem Ermessen und ohne Ank&uuml;ndigung Texte ver&auml;ndern oder l&ouml;schen und ist
    nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Website
    geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder Partner sind nicht
    verantwortlich f&uuml;r Sch&auml;den, wie direkte, indirekte, zuf&auml;llige, vorab konkret zu bestimmende oder
    Folgesch&auml;den, die angeblich durch den Besuch dieser Website entstanden sind und &uuml;bernehmen hierf&uuml;r
    folglich keine Haftung.</p>
  <p>Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung und Haftung f&uuml;r die Inhalte und die Verf&uuml;gbarkeit
    von Website Dritter, die &uuml;ber externe Links dieser Website erreichbar sind. F&uuml;r den Inhalt der verlinkten
    Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich damit ausdr&uuml;cklich
    von allen Inhalten Dritter, die m&ouml;glicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten
    Sitten verstossen.</p>
  <p>&nbsp;</p>
  <h2 class="inner">&Auml;nderungen</h2>
  <p>Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne Vorank&uuml;ndigung anpassen. Es gilt die jeweils
    aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung Teil einer Vereinbarung mit
    Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die &Auml;nderung per E-Mail oder auf andere geeignete
    Weise informieren.</p>
  <p>&nbsp;</p>
  <h2 class="inner">Fragen an den Datenschutzbeauftragten</h2>
  <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r
    den Datenschutz zu Beginn der Datenschutzerkl&auml;rung aufgef&uuml;hrten, verantwortlichen Person in unserer
    Organisation.</p>
  <p>&nbsp;</p>
  <p>Weyregg, 02.09.2020
    <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.-->
    <br/>Quelle: <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a></p>
  <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
</div>
