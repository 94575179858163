import {Subject} from 'rxjs/Subject';
import {Holiday} from '../models/holiday.model';
import {Headers, Http, Response} from '@angular/http';
import {AuthService} from '../../auth/auth.service';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import {environment} from '../../../environments/environment';

@Injectable()
export class HolidayService {

  holidaysChanged = new Subject<Holiday[]>();
  holidays: Holiday[] = [];

  constructor(private http: Http, private authService: AuthService) {
  }

  getHolidays() {
    return this.holidays.slice();
  }

  getDisplayText() {
    const today = new Date();
    for (let holiday of this.holidays) {
      const from = new Date(holiday.displayTextFrom);
      const to = new Date(holiday.to);
      if (from <= today && to >= today) {
        return holiday.displayText;
      }
    }
  }

  getHolidayAt(date: Date) {
    for (let holiday of this.holidays) {
      const from = new Date(holiday.from);
      const to = new Date(holiday.to);
      if (from.getTime() <= date.getTime() && to.getTime() >= date.getTime()) {
        return holiday;
      }
    }
  }

  getHoliday(id: string) {
    for (let holiday of this.holidays) {
      if (holiday.id === id) {
        return holiday;
      }
    }
  }

  loadHolidays() {
    firebase.database().ref('holidays').orderByChild('from').once('value')
      .then((snapshot: DataSnapshot) => {
        this.holidays = [];
        snapshot.forEach((childSnapshot) => {
            this.holidays.push(childSnapshot.val());
            return false;
          }
        );
        this.holidaysChanged.next(this.holidays.slice());
      });
  }

  persistHolidays() {
    const headers = new Headers({'Content-Type': 'application/json'});
    const token = this.authService.getToken();
    return this.http.put('https://' + environment.firebase.projectId + '.firebaseio.com/holidays.json?auth=' + token, this.holidays, {headers: headers})
      .subscribe(
        (response) => {
          this.holidaysChanged.next(this.holidays.slice());
        },
        (error) => console.log(error)
      );
  }

  addHoliday(holiday: Holiday) {
    this.holidays.push(holiday);
    this.persistHolidays();
    this.holidaysChanged.next(this.holidays.slice());
  }

  updateHoliday(id: string, newHoliday: Holiday) {
    for (let i = 0; i < this.holidays.length; i++) {
      if (this.holidays[i].id === id) {
        this.holidays[i] = newHoliday;
      }
    }
    this.persistHolidays();
    this.holidaysChanged.next(this.holidays.slice());
  }

  deleteHoliday(id: string) {
    let removeIndex;
    for (let i = 0; i < this.holidays.length; i++) {
      if (this.holidays[i].id === id) {
        removeIndex = i;
      }
    }
    if (removeIndex) {
      this.holidays.splice(removeIndex, 1);
    }
    this.holidaysChanged.next(this.holidays.slice());
  }

}
