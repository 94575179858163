<div class="row">
  <div class="col-xs-12">
    <button class="btn btn-success" (click)="onNewProduct()">Neues Produkt erfassen</button>
  </div>
</div>
<hr>
<div>
  <div class="row">
    <div class="col-xs-12">
      <app-admin-product-item
        *ngFor="let product of products; let i = index"
        [product]="product"
        [index]="i"
      ></app-admin-product-item>
    </div>
  </div>
</div>
