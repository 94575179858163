import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShoppingCartService} from '../../shared/services/shopping-cart.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscriber} from 'rxjs/Subscriber';
import {Subscription} from 'rxjs/Subscription';
import {OrderItem} from '../../shared/models/order-item.model';
import {Order} from '../../shared/models/order.model';
import {DeliveryArea} from '../../shared/models/delivery-area.model';
import {DeliveryAreaService} from '../../shared/services/delivery-area.service';
import {AuthService} from '../../auth/auth.service';
import {DeliveryOption} from '../../shared/models/delivery-option.model';
import {NgForm} from '@angular/forms';
import {OrderService} from '../../shared/services/order.service';
import {HolidayService} from '../../shared/services/holiday.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  private shoppingCartSubscription: Subscription;
  private activeUserChangedSubscription: Subscription;
  private orderCompletedSubscription: Subscription;
  shoppingCartItems: OrderItem[];
  totalPrice: number = 0.0;
  deliveryArea: DeliveryArea;
  deliveryOptions: DeliveryOption[] = [];
  holidayDisplayText: string;

  @ViewChild('f') cartForm: NgForm;

  constructor(private shoppingCartService: ShoppingCartService,
              private router: Router,
              private route: ActivatedRoute,
              private deliveryAreaService: DeliveryAreaService,
              private holidayService: HolidayService,
              private authService: AuthService,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.deliveryAreaService.loadDeliveryAreas();
    this.shoppingCartItems = this.shoppingCartService.getItems();
    this.totalPrice = this.shoppingCartService.getTotalPrice();
    this.shoppingCartSubscription = this.shoppingCartService.shoppingCartChanged.subscribe(
      ($event) => {
        this.shoppingCartItems = $event.items;
        this.totalPrice = $event.totalPrice;
      }
    );

    this.activeUserChangedSubscription = this.authService.activeUserChanged.subscribe(
      (value) => {
        this.deliveryOptions = this.deliveryAreaService.findDeliveryOptions(value.user.deliveryAreaId);
      }
    );
    if (this.authService.activeUser !== undefined) {
      this.holidayDisplayText = this.holidayService.getDisplayText();
      this.deliveryOptions = this.deliveryAreaService.findDeliveryOptions(this.authService.activeUser.deliveryAreaId);
    }

    this.orderCompletedSubscription = this.orderService.orderCompleted.subscribe(
      value => {
        this.shoppingCartService.resetCart();
      }
    );

  }

  onSubmit() {
    this.orderService.createNewOrder(this.shoppingCartItems.slice(), this.deliveryOptions[this.cartForm.value.deliveryOptionId]);
    this.router.navigate(['checkout'], {relativeTo: this.route});
  }

  ngOnDestroy(): void {
    this.shoppingCartSubscription.unsubscribe();
    this.activeUserChangedSubscription.unsubscribe();
  }

  onAddItem(i) {
    this.shoppingCartService.onAddItem(i);
  }

  onRemoveItem(i) {
    this.shoppingCartService.onRemoveItem(i);
  }

  canCheckout(): boolean {
    return this.shoppingCartService.canCheckout();
  }

}
