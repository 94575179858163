<div class="container margin_60_35">
  <div id="tabs" class="tabs">
    <nav class="col--md-12 col-sm-12 col-xs-12">
      <div class="admin-menu">
        <ul>
          <li><a routerLink="/admin/products" routerLinkActive="active">Produkte</a></li>
          <li><a routerLink="/admin/user-regs" routerLinkActive="active">Benutzer Registrierung</a></li>
          <li><a routerLink="/admin/holidays" routerLinkActive="active">Ferien</a></li>
        </ul>
      </div>
    </nav>

    <router-outlet></router-outlet>

  </div>
</div>
