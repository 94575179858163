<div class="row">
  <div class="col-xs-12">
    <form [formGroup]="holidayForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="id">Id</label>
              <input type="text" id="id" class="form-control" formControlName="id" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" class="form-control" formControlName="name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="from">Von Datum (yyyy-MM-dd)</label>
              <input type="text" id="from" class="form-control" formControlName="from">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="to">Bis Datum (yyyy-MM-dd)</label>
              <input type="text" id="to" class="form-control" formControlName="to">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="displayText">Anzeigetext</label>
              <input type="text" id="displayText" class="form-control" formControlName="displayText">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="displayTextFrom">Anzeigen von Datum (yyyy-MM-dd)</label>
              <input type="text" id="displayTextFrom" class="form-control" formControlName="displayTextFrom">
            </div>
          </div>
        </div>

        <div class="col-xs-12">
          <button type="submit" [disabled]="!holidayForm.valid" class="btn btn-success">Speichern</button>
          <button type="button" class="btn btn-danger" (click)="onCancel()">Abbrechen</button>
        </div>
      </div>
    </form>
  </div>
</div>
