import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-signin-cart',
  templateUrl: './signin-cart.component.html',
  styleUrls: ['./signin-cart.component.css']
})
export class SigninCartComponent implements OnInit, OnDestroy {

  private loginStateSubscription: Subscription;
  wrongPassword: boolean;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.loginStateSubscription = this.authService.loginStateChanged.subscribe((value) => {
      if (this.authService.errorCode === 'auth/wrong-password') {
        this.wrongPassword = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.loginStateSubscription.unsubscribe();
  }

  onSignin(form: NgForm) {
    this.reset();
    const email = form.value.email;
    const password = form.value.password;
    this.authService.signinUser(email, password);
  }

  private reset() {
    this.wrongPassword = false;
  }
}
