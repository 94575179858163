import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'weight_gram'
})
export class WeightGramPipe implements PipeTransform {

  transform(value: any, ...args): any {
    const grams = value.toFixed(0);
    if (grams < 0.01) {
      return '';
    }
    else if (grams >= 1000) {
      return 'ca.' + (grams / 1000) + 'kg';
    } else {
      return 'ca.' + grams + 'g';
    }
  }

}
