import { Component, OnInit } from '@angular/core';
import {EmailValidator, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {

  lostPasswordForm: FormGroup;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.initForm(); // refresh form content
  }

  onSubmit() {
    this.authService.resetPassword(this.lostPasswordForm.value.email);
    this.lostPasswordForm.reset();
    this.router.navigate(['/']);
  }

  private initForm() {
    this.lostPasswordForm = new FormGroup({
      'email': new FormControl('', [Validators.required]),
    });
  }
}
