import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated() && this.authService.activeUser != null) {
      return this.authService.activeUser.email === 'silvan.krebs@gmail.com'
        || this.authService.activeUser.email === 'chrebsli@gmail.com';
    } else {
      this.router.navigate(['/signin']);
    };
  }
}
