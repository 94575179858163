<div class="wrapper_indent">
  <p>
    Hoppla, Sie sind noch keine freigeschaltete Kundschaft. Bitte registrieren Sie sich vor der ersten Bestellung, damit
    wir Ihnen per Mail eine Freigabe erteilen können. Herzlichen Dank.
  </p>
  <button class="btn_full" routerLink="/signup">Jetzt Registrieren</button>
  <hr>
  <h4>Bereits registriert?</h4>
  <form (ngSubmit)="onSignin(f)" #f="ngForm">
    <div class="form-group">
      <input class="form-control" name="email" id="email" type="email" placeholder="Email" ngModel class="form-control">
    </div>
    <div class="form-group">
      <input class="form-control" name="password" id="password" type="password" placeholder="Passwort" ngModel
             class="form-control">
    </div>
    <div class="form-group" *ngIf="wrongPassword">
      <div class="alert alert-danger">
        Anmeldung fehlgeschlagen. <br/> E-Mail oder Passwort falsch.
      </div>
    </div>
    <div class="form-group">
      <div>
        <a routerLink="/lost-password">Passwort vergessen?</a>
      </div>
    </div>
    <button type="submit" class="btn_full">Anmelden</button>
  </form>
</div>
