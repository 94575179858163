<div class="col-md-8">
  <app-product-list></app-product-list>
</div>

<div class="col-md-4" id="sidebar">
  <div class="theiaStickySidebar">
    <div id="cart_box">
      <h4>Ihre Bestellung <i class="icon_cart_alt pull-right"></i></h4>
      <app-shopping-cart *ngIf="isAuthenticated()"></app-shopping-cart>
      <app-signin-cart *ngIf="!isAuthenticated()"></app-signin-cart>
    </div>
  </div>
</div>
