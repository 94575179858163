export const environment = {
  production: true,
  from_email: 'baeckereireider.at@gmail.com',
  bcc_email: 'baeckereireider.at@gmail.com',
  firebase: {
    apiKey: 'AIzaSyD5NBBCPtUeybVXCRRtGAQaZawNR3s0bRI',
    authDomain: 'reider-app.firebaseapp.com',
    databaseURL: 'https://reider-app.firebaseio.com',
    projectId: 'reider-app',
    storageBucket: 'reider-app.appspot.com',
    messagingSenderId: '764093484499'
  }
};
