<div class="box_style_2" id="order_process">
  <h2 class="inner">Bestellübersicht</h2>
  <div class="row">

    <table class="table table-striped nomargin">
      <tbody>
      <tr>
        <td>
          <strong>1x</strong> Enchiladas
        </td>
        <td>
          <strong class="pull-right">$11</strong>
        </td>
      </tr>
      <tr>
        <td>
          <strong>2x</strong> Burrito
        </td>
        <td>
          <strong class="pull-right">$14</strong>
        </td>
      </tr>
      <tr>
        <td>
          <strong>1x</strong> Chicken
        </td>
        <td>
          <strong class="pull-right">$20</strong>
        </td>
      </tr>
      <tr>
        <td>
          <strong>2x</strong> Corona Beer
        </td>
        <td>
          <strong class="pull-right">$9</strong>
        </td>
      </tr>
      <tr>
        <td>
          <strong>2x</strong> Cheese Cake
        </td>
        <td>
          <strong class="pull-right">$12</strong>
        </td>
      </tr>
      <tr>
        <td class="total_confirm">
          TOTAL
        </td>
        <td class="total_confirm">
          <span class="pull-right">$66</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <div class="row">
    <div class="col-md-12">
      <h4>Lieferadresse</h4>
      <ul class="order_delivery_address">
        <li>Silvan Krebs</li>
        <li>Nordring 46</li>
        <li>3013 Bern</li>
      </ul>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12">
      <h4>Lieferdatum</h4>
      Liefertour: <strong>1234 Weyregg</strong>
      <select>
        <option>
          Donnerstag, 29.03.2018
        </option>
        <option>
          Montag, 02.04.2018
        </option>
      </select>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12">
      <h4>Notiz</h4>
      <textarea class="form-control" style="height:150px" placeholder="Ex. Allergies, cash change..." name="notes"
                id="notes"></textarea>
    </div>
  </div>

</div><!-- End box_style_1 -->
