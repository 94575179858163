import {Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';
import {User} from '../../shared/models/user.model';
import DataSnapshot = firebase.database.DataSnapshot;
import {AuthService} from '../../auth/auth.service';
import {DeliveryAreaService} from '../../shared/services/delivery-area.service';
import {MailService} from '../../shared/services/mail.service';

@Component({
  selector: 'app-admin-user-reg',
  templateUrl: './admin-user-reg.component.html',
  styleUrls: ['./admin-user-reg.component.css']
})
export class AdminUserRegComponent implements OnInit {

  openUserRegs: User[] = [];

  constructor(private authService: AuthService,
              private deliveryAreaService: DeliveryAreaService,
              private mailService: MailService) {
  }

  ngOnInit() {
    this.loadOpenRegistrations();
  }

  loadOpenRegistrations() {
    firebase.database().ref('users')
      .orderByChild('status')
      .startAt('REG').endAt('REG')
      .once('value')
      .then((snapshot: DataSnapshot) => {
          snapshot.forEach(childSnapshot => {
              const user = (<User> childSnapshot.val());
              user.id = childSnapshot.key;
              const deliveryArea = this.deliveryAreaService.findDeliveryArea(user.deliveryAreaId);
              user.zip = deliveryArea.zip;
              user.city = deliveryArea.city;
              this.openUserRegs.push(user);
              return false;
            }
          );
        }
      )
      .catch(
        error => console.error(error)
      );
  }
  onActivateUser(i) {
    const user = this.openUserRegs[i];

    this.authService.signupUser(user)

    user.status = 'TEMPPW';
    firebase.database().ref('users/' + user.id).update(user)
      .then(
        (response) => {
          this.openUserRegs.splice(i, 1);
        }
      )
      .catch(
        error => console.error(error)
      );


  }

  onDeclineUser(i) {
    const user = this.openUserRegs[i];
    user.status = 'DECLINE';
    firebase.database().ref('users/' + user.id).update(user)
      .then(
        (response) => {
          this.mailService.sendEmailDecline(user.email);
          this.openUserRegs.splice(i, 1);
        }
      )
      .catch(
        error => console.error(error)
      );

  }

}
