<div class="container margin_60_35">
  <div class="row">
    <div class="col-md-3">
      <app-customer-menu></app-customer-menu>
    </div>

    <div class="col-md-6">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
