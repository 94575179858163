
<div class="row">
  <div class="col-md-offset-3 col-md-6">
    <div class="box_style_2">
      <div id="confirm">
        <h3>Vielen Dank!</h3>
        <p>
          Sobald wir Ihre Anmeldung geprüft haben, werden Sie von uns per E-Mail informiert.
        </p>
        <p>
          Ihr B&auml;ckerei Reider Team
        </p>
      </div>
    </div>
    <div>
      <button class="btn btn_full_outline" (click)="onBackToShop()">Zur&uuml;ck zu den Produkten</button>
    </div>
  </div>
</div>
