import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {ProductService} from '../../../shared/services/product.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import * as uuid from 'uuid';
import {ProductCategoryService} from '../../../shared/services/product-category.service';
import {ProductCategory} from '../../../shared/models/product-category.model';
import {Subscription} from 'rxjs/Subscription';
import {Product} from '../../../shared/models/product.model';

@Component({
  selector: 'app-admin-product-detail',
  templateUrl: './admin-product-detail.component.html',
  styleUrls: ['./admin-product-detail.component.css']
})
export class AdminProductDetailComponent implements OnInit, OnDestroy {
  private categorySubscription: Subscription;
  private productSubscription: Subscription;

  index: string;
  categories: ProductCategory[];
  products:Product[];
  productForm: FormGroup;

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private categoryService: ProductCategoryService,
              private router: Router) {
  }

  ngOnInit() {
    this.categorySubscription = this.categoryService.categoriesChanged.subscribe(
      (categories: ProductCategory[]) => {
        this.categories = categories;
      }
    );
    this.categories = this.categoryService.getCategories();

    this.productSubscription = this.productService.productsChanged.subscribe(
      (products: Product[]) => {
        this.products = products;
      }
    )
    this.products = this.productService.getProducts();

    this.route.params.subscribe(
      (params: Params) => {
        this.index = params['id'];
        this.initForm(); // refresh form content
      }
    );
    this.initForm(); // refresh form content
  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
    this.productSubscription.unsubscribe();
  }

  onSubmit() {
    if (this.index) {
      this.productService.updateProduct(this.products[this.index].id, this.productForm.value); // because form value has the same form as recipe
    } else {
      this.productService.addProduct(this.productForm.value);
    }
    this.onCancel();
  }

  private initForm() {
    let id = '';
    let name = '';
    let imagePath = '';
    let description = '';
    let categoryId = '';
    let weightGrams = 0;
    let price = 0.0;
    let onRequest = false;
    let visible = false;
    let available = true;

    if (this.index) {
      const product = this.products[this.index];
      id = product.id;
      name = product.name;
      imagePath = product.imagePath;
      description = product.description;
      categoryId = product.categoryId;
      weightGrams = product.weightGrams;
      price = product.price;
      onRequest = product.onRequest;
      visible = product.visible;
      available = product.available;
    } else {
      id = uuid.v4();
    }

    this.productForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'imagePath': new FormControl(imagePath, Validators.required),
      'description': new FormControl(description),
      'categoryId': new FormControl(categoryId, Validators.required),
      'weightGrams': new FormControl(weightGrams, Validators.required),
      'price': new FormControl(price, Validators.required),
      'onRequest': new FormControl(onRequest, Validators.required),
      'visible': new FormControl(visible, Validators.required),
      'available': new FormControl(available, Validators.required)
    });
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}
