export class DeliveryOption {

  public deliveryArea: string;
  public date: Date;


  constructor(deliveryArea: string, date: Date) {
    this.deliveryArea = deliveryArea;
    this.date = date;
  }
}
