import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import * as firebase from 'firebase';
import {Order} from '../models/order.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class MailService {

  constructor() {
  }

  sendEmailWelcome(user: User, password: string) {
    const date = Date();
    const html = '<b>Hallo ' + user.firstname + ' ' + user.name + '</b><br/><br/>Herzlich willkommen in unserem Online-Shop!<br/><br/>Dein tempor&auml;res Passwort lautet: ' + password + '<br/><br/>Wir danken f&uuml;r Dein Interesse und w&uuml;nschen viel Spass mit unserem Angebot.<br/><br/>Freundliche Gr&uuml;sse<br/><b>B&auml;ckerei Reider</b><br/><br/><small>Kontaktieren Sie uns f&uuml;r Sonderw&uuml;nsche, Grossauftr&auml;ge oder kleinere Mengen bitte telefonisch unter +4366475044327 (Mobilbox, WhatsApp oder SMS, wir rufen zur&uuml;ck).</small>';
    const message = {
      date: date, email: user.email, message: {
        subject: 'Bestätigung Kontoeröffnung',
        html: html,
        text: 'Hallo ' + user.firstname + ' ' + user.name + '\n' +
        '\n' +
        'Herzlich willkommen in unserem Online-Shop!\n' +
        '\n' +
        'Ihr temporäres Passwort lautet: ' + password + '\n' +
        '\n' +
        'Wir danken für Ihr Interesse und wünschen viel Spass mit unserem Angebot.\n' +
        '\n' +
        'Freundliche Grüsse\n' +
        'Bäckerei Reider\n' +
        '\n' +
        'Kontaktieren Sie uns für Sonderwünsche, Grossaufträge oder kleinere Mengen bitte telefonisch unter +4366475044327 (Mobilbox, WhatsApp oder SMS, wir rufen zurück).'
      }
    };

    const messageKey = firebase.database().ref('messages').push(message);
  }

  sendOrderConfirmation(user: User, order: Order) {
    const date = Date();

    let productRows = '';
    for (let item of order.items) {
      productRows += '<tr><td><strong>' + item.quantity + 'x</strong> ' + item.product.name + '</td><td><strong>' + item.getTotalPrice().toFixed(2) + ' €</strong></td></tr>';
    }

    let address = '';
    for (let addressLine of order.addressLines) {
      address += '<tr><td>' + addressLine + '</td></tr>';
    }
    address += '<tr><td> </td></tr>';

    const html = '<p>Hallo ' + user.firstname + ' ' + user.name + '</p>' +
      '<p>Vielen Dank f&uuml;r Ihre Bestellung bei der B&auml;ckerei Reider.</p>' +
      '<h4>Ihre bestellten Produkte</h4>' +
      '<table>' +
      '\t<tbody>' +
      productRows +
      '<tr><td class="total_confirm"><strong>Total</strong></td><td><strong>' + order.totalPrice.toFixed(2) + ' €</strong></td></tr>' +
      '\t</tbody>' +
      '</table>\t\t' +
      '<h4>Lieferdatum</h4>' +
      '<p>' + order.deliveryOption.deliveryArea + ', ' + this.formatDate(order.deliveryOption.date) + '</p>' +
      '<p>Geliefert wird im Verlaufe des Vormittags bis ca. 14:00 Uhr!</p>' +
      '<h4>Lieferadresse</h4>' +
      '<table>' +
      '<tbody>' +
      address +
      '</tbody>' +
      '</table>' +
    '<h4>Kontakt</h4>' +
    '<table>' +
    '<tbody>' +
      '<tr><td>Telefon: </td><td>' + user.phone + '</td></tr>' +
      '<tr><td>E-Mail: </td><td>' + user.email + '</td></tr>' +
    '</tbody>' +
    '</table>' +
      '<h4>Ihre Notiz an uns</h4>' +
      '<p>' + order.notes + '</p>' +
      '<p>Besten Dank f&uuml;r Ihr Vertrauen in unsere Produkte.</p>' +
    '<p>Mit freundlichen Gr&uuml;ssen</p>' +
    '<p>Ihr B&auml;ckerei Reider Team</p>';
    const message = {
      date: date, email: order.userEmail,
      email_bcc: environment.bcc_email,
      message: {
        subject: 'Ihre Bestellung bei Bäckerei Reider',
        html: html,
        text: 'txt'
      }
    };
    const messageKey = firebase.database().ref('messages').push(message);
  }

  private formatDate(date: Date): string {
    return this.pad(date.getDate().toFixed(0)) + '.' + this.pad((date.getMonth() + 1).toFixed(0)) + '.' + date.getFullYear().toFixed(0);
  }

  private pad(num: string) {
    let s = '00' + num;
    return s.substr(s.length - 2);
  }

  sendEmailDecline(email: string) {
    const date = Date();
    const html = '<p>Sehr geehrte Kundschaft</p>\n' +
      '<p>Danke f&uuml;r Ihr Interesse an unseren Produkten.</p>' +
      '<p>Leider ist unser Kundenkontingent derzeit ausgesch&ouml;pft. Deshalb k&ouml;nnen Sie zurzeit nicht ber&uuml;cksichtigt werden.</p>' +
      '<p>Danke f&uuml;r Ihr Verst&auml;ndnis.</p>' +
      '<p>Mit freundlichen Gr&uuml;ssen</p>' +
      '<p>Ihr B&auml;ckerei Reider Team</p>';

    const message = {
      date: date, email: email,
      message: {
        subject: 'Ihre Anmeldung bei Bäckerei Reider',
        html: html,
        text: 'txt'
      }
    };
    const messageKey = firebase.database().ref('messages').push(message);
  }



  sendNewUserRegNotification(user: User) {
    const date:Date = new Date();
    const html = '<b>Neue Benutzeranmeldung: ' + user.firstname + ' ' + user.name + '</b><br/><br/>Zeitpunkt: ' + date.toISOString() + '<br/><br/><a href="https://www.baeckerei-reider.at/admin/user-regs">User Registrierungen anzeigen</a>';
    const message = {
      date: date, email: environment.bcc_email, message: {
        subject: 'Neue Benutzeranmeldung: ' + user.firstname + ' ' + user.name,
        html: html,
        text: 'Neue Benutzeranmeldung: ' + user.firstname + ' ' + user.name + '\n\n' +
        'User Registrierungen anzeigen: https://www.baeckerei-reider.at/admin/user-regs">User Registrierungen anzeigen'
      }
    };

    const messageKey = firebase.database().ref('messages').push(message);

  }

}
