import {Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';
import {AuthService} from './auth/auth.service';
import {environment} from '../environments/environment';
import {ProductService} from './shared/services/product.service';
import {DeliveryAreaService} from './shared/services/delivery-area.service';
import {HolidayService} from './shared/services/holiday.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private authService: AuthService,
              private productService: ProductService,
              private holidayService: HolidayService,
              private deliveryAreaService: DeliveryAreaService) {
  }

  ngOnInit() {
    firebase.initializeApp(environment.firebase);
    this.deliveryAreaService.loadDeliveryAreas();
    this.productService.loadProducts();
    this.holidayService.loadHolidays();
    this.authService.reloadAuthState();
  }

}
