import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {UserService} from '../../shared/services/user.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {DeliveryArea} from '../../shared/models/delivery-area.model';
import {Subscriber} from 'rxjs/Subscriber';
import {Subscription} from 'rxjs/Subscription';
import {DeliveryAreaService} from '../../shared/services/delivery-area.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit, OnDestroy {

  private deliveryAreasChangedSubscription: Subscription;
  userForm: FormGroup;
  deliveryAreas: DeliveryArea[];

  constructor(private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private deliveryAreaService: DeliveryAreaService) { }

  ngOnInit() {
    this.deliveryAreasChangedSubscription = this.deliveryAreaService.deliveryAreasChanged.subscribe(
      (deliveryAreas: DeliveryArea[]) => {
        this.deliveryAreas = deliveryAreas;
      }
    )
    this.deliveryAreas = this.deliveryAreaService.getDeliveryAreas();

    this.initForm(); // refresh form content
  }

  ngOnDestroy(): void {
    this.deliveryAreasChangedSubscription.unsubscribe();
  }

  onSubmit() {
    this.userService.registerUser(this.userForm.value);
    this.userForm.reset();
    this.router.navigate(['/signup/confirm']);
  }

  private initForm() {
    let firstname = '';
    let name = '';
    let phone = '';
    let email = '';
    let street = '';
    let deliveryAreaId = '';

    this.userForm = new FormGroup({
      'firstname': new FormControl(firstname, Validators.required),
      'name': new FormControl(name, Validators.required),
      'phone': new FormControl(phone, Validators.required),
      'email': new FormControl(email, Validators.required),
      'street': new FormControl(street, Validators.required),
      'deliveryAreaId': new FormControl(deliveryAreaId, Validators.required)
    });
  }
}
