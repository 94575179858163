<div class="container margin_60">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="content">
        <section id="section-3">
          <div class="row">
            <form [formGroup]="lostPasswordForm" (ngSubmit)="onSubmit()">
              <div class="box_style_2" id="signup">
                <h3 class="nomargin_top" style="text-align: center;">Passwort vergessen</h3>
                <div class="form-group">
                  <label>E-Mail</label>
                  <input type="email" id="email" name="email" class="form-control" placeholder="E-Mail"
                         class="form-control" formControlName="email" email="true">
                </div>
                <hr>
                <button class="btn_full" type="submit" [disabled]="!lostPasswordForm.valid">Neues Passwort anfordern</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

