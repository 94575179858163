<form (ngSubmit)="onSubmit()" #f="ngForm">
  <table class="table table_summary" *ngIf="shoppingCartItems.length > 0">
    <tbody>
    <tr *ngFor="let item of shoppingCartItems; let i = index">
      <td nowrap>
        <a style="cursor: pointer;" class="remove_item" (click)="onRemoveItem(i)"><i class="icon_minus_alt"></i></a>
        <a style="cursor: pointer;" class="add_item" (click)="onAddItem(i)"><i class="icon_plus_alt"></i></a>
        <strong>{{item.quantity}}x</strong>
        {{item.product.name}}
      </td>
      <td nowrap>
        <strong class="pull-right">{{item.getTotalPrice() | euro}}</strong>
      </td>
    </tr>
    </tbody>
  </table>
  <table class="table table_summary" *ngIf="shoppingCartItems.length < 1">
    <tbody>
    <tr>
      <td>
        <i>Keine Produkte gew&auml;hlt</i>
      </td>
    </tr>
    </tbody>
  </table>
  <hr *ngIf="shoppingCartItems.length > 0">
  <table class="table table_summary" *ngIf="shoppingCartItems.length > 0">
    <tbody>
    <tr *ngIf="deliveryOptions.length > 0">
      <td nowrap><strong>Lieferdatum:</strong></td>
    </tr>
    <tr *ngIf="deliveryOptions.length > 0">
      <td nowrap>
        <div *ngFor="let deliveryOption of deliveryOptions; let i = index;">
          <input type="radio" [value]="i" checked name="deliveryOptionId" class="icheck" ngModel required
          > {{deliveryOption.deliveryArea}}: {{deliveryOption.date | date:'dd.MM.yyyy'}}
        </div>
      </td>
    </tr>
    <tr *ngIf="deliveryOptions.length < 1">
      <td class="font_red">
        F&uuml;r Ihre Adresse sind zur Zeit leider keine Lieferungen geplant.
      </td>
    </tr>
    <tr *ngIf="holidayDisplayText">
      <td class="font_holiday">
        {{holidayDisplayText}}
      </td>
    </tr>
    </tbody>
  </table>
  <hr>
  <table class="table table_summary">
    <tbody>
    <tr>
      <td class="total">
        Total<span class="pull-right">{{totalPrice | euro}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <small class="pull-right">inkl. Gratis-Lieferung</small>
      </td>
    </tr>
    <tr><td><small>Geliefert wird im Verlaufe des Vormittags bis ca. 14:00 Uhr!</small></td></tr>
    </tbody>
  </table>
  <hr>
  <button class="btn_full" type="submit" [disabled]="!cartForm.valid || !canCheckout()"
          *ngIf="shoppingCartItems.length > 0">Jetzt Bestellen
  </button>
  <table class="table table_summary">
    <tbody>
    <tr>
      <td nowrap>
        <small>Mindestbestellmenge: <strong>5 €</strong></small>
        <br>
        <small>Maximale Bestellmenge: <strong>100 €</strong></small>
      </td>
    </tr>
    <tr>
      <td>
        <small>Alle Preise verstehen sich inkl. MwSt.</small>
      </td>
    </tr>
    </tbody>
  </table>
</form>
