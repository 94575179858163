<div class="row">
  <div class="col-xs-12">
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="id">Id</label>
              <input type="text" id="id" class="form-control" formControlName="id" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" class="form-control" formControlName="name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="categoryId">Produktgruppe</label>
              <div class="styled-select">
                <select class="form-control" name="categoryId" id="categoryId" formControlName="categoryId" >
                    <option  *ngFor="let category of categories" [value]="category.id" [selected]="category.id === categoryId">{{category.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="weightGrams">Gewicht in Gram</label>
              <input type="number" id="weightGrams" class="form-control" formControlName="weightGrams">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="price">Preis</label>
              <input type="number" id="price" class="form-control" formControlName="price">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="imagePath">Image URL</label>
              <input type="text" id="imagePath" class="form-control" formControlName="imagePath" #imagePath>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <img [src]="imagePath.value" alt="" class="img-responsive">
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="description">Description</label>
              <textarea id="description" rows="6" class="form-control" formControlName="description"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="description">nur auf Anfrage</label>
              <input type="checkbox" id="onRequest" rows="6" class="form-control" formControlName="onRequest">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="description">sichtbar</label>
              <input type="checkbox" id="visible" rows="6" class="form-control" formControlName="visible">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="description">Verfügbar</label>
              <input type="checkbox" id="available" rows="6" class="form-control" formControlName="available">
            </div>
          </div>
        </div>

        <div class="col-xs-12">
          <button type="submit" [disabled]="!productForm.valid" class="btn btn-success">Speichern</button>
          <button type="button" class="btn btn-danger" (click)="onCancel()">Abbrechen</button>
        </div>
      </div>
    </form>
  </div>
</div>
