<div class="container margin_60">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="content">
        <section id="section-3">
          <form (ngSubmit)="onSignin(f)" #f="ngForm">
            <div class="col-md-12 col-sm-12 add_bottom_15">
              <div class="indent_title_in">
                <i class="icon_lock_alt"></i>
                <h3>Bestehender Kunde</h3>
              </div>
              <div class="wrapper_indent">
                <div class="form-group">
                  <label>E-Mail</label>
                  <input class="form-control" name="email" id="email" type="email" ngModel value="silvan.krebs@gmail.com">
                </div>
                <div class="form-group">
                  <label>Passwort</label>
                  <input class="form-control" name="password" id="password" type="password" ngModel value="a7epp7bt" >
                </div>
                <button type="submit" class="btn_full">Anmelden</button>
              </div><!-- End wrapper_indent -->
            </div>
          </form>

          <div class="col-md-12 col-sm-12 add_bottom_15">
            <div class="indent_title_in">
              <i class="icon_mail_alt"></i>
              <h3>Noch nicht registriert?</h3>
            </div>
            <div class="wrapper_indent">
              <p>
                Um unseren Online-Shop nutzen zu können, müssen Sie sich vor der ersten Bestellung registrieren.
              </p>
              <button class="btn_full" routerLink="/signup">Jetzt Registrieren</button>
            </div><!-- End wrapper_indent -->
          </div>
        </section><!-- End section 3 -->
      </div>

    </div>
  </div>
</div>
