import {OrderItem} from './order-item.model';
import {DeliveryOption} from './delivery-option.model';

export class Order {

  id: string;
  userId: string;
  userEmail: string;
  addressLines: string[];
  items: OrderItem[];
  totalPrice: number;
  deliveryOption: DeliveryOption;
  status: string;
  notes: string;
  orderDate: Date;

  constructor(id: string, userId: string, userEmail: string, addressLines: string[], items: OrderItem[], totalPrice: number, deliveryOption: DeliveryOption, status: string, notes: string, orderDate: Date) {
    this.id = id;
    this.userId = userId;
    this.userEmail = userEmail;
    this.addressLines = addressLines;
    this.items = items;
    this.totalPrice = totalPrice;
    this.deliveryOption = deliveryOption;
    this.status = status;
    this.notes = notes;
    this.orderDate = orderDate;
  }

}
