import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {ShopComponent} from './shop/shop.component';
import {ProductListComponent} from './shop/product-list/product-list.component';
import {ProductListItemComponent} from './shop/product-list-item/product-list-item.component';
import {ProductListToolsComponent} from './shop/product-list-tools/product-list-tools.component';
import {ShoppingCartComponent} from './shop/shopping-cart/shopping-cart.component';
import {ProductService} from './shared/services/product.service';
import {ShoppingCartService} from './shared/services/shopping-cart.service';
import {CheckoutComponent} from './shop/checkout/checkout.component';
import {HolidayService} from './shared/services/holiday.service';

import {AppRoutingModule} from './app-routing.module';
import {OrderConfirmationComponent} from './shop/checkout/order-confirmation/order-confirmation.component';
import {ProductsComponent} from './shop/products/products.component';
import {OrderAddressComponent} from './shop/checkout/order-address/order-address.component';


import {HelpComponent} from './help/help.component';
import {CustomerAccountComponent} from './customer/customer-account/customer-account.component';
import {CustomerComponent} from './customer/customer.component';
import {CustomerMenuComponent} from './customer/customer-menu/customer-menu.component';
import {CustomerOrdersComponent} from './customer/customer-orders/customer-orders.component';
import {ICheckDirective} from './shared/icheck.directive';
import {EuroPipe} from './shared/pipes/euro.pipe';
import {WeightGramPipe} from './shared/pipes/weight-gram.pipe';
import {AuthService} from './auth/auth.service';
import {AuthGuardService} from './auth/auth-guard.service';
import {SignupComponent} from './auth/signup/signup.component';
import {SigninComponent} from './auth/signin/signin.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {AdminComponent} from './admin/admin.component';
import {AdminProductsComponent} from './admin/admin-products/admin-products.component';
import {AdminProductListComponent} from './admin/admin-products/admin-product-list/admin-product-list.component';
import {AdminProductItemComponent} from './admin/admin-products/admin-product-item/admin-product-item.component';
import {AdminProductDetailComponent} from './admin/admin-products/admin-product-detail/admin-product-detail.component';
import {AdminAuthGuardService} from './auth/admin-auth-guard.service';
import {ProductCategoryService} from './shared/services/product-category.service';
import {FooterComponent} from './footer/footer.component';
import {UserService} from './shared/services/user.service';
import {OrderCheckoutComponent} from './shop/checkout/order-checkout/order-checkout.component';
import {SigninCartComponent} from './shop/signin-cart/signin-cart.component';
import {AdminUserRegComponent} from './admin/admin-user-reg/admin-user-reg.component';
import {MailService} from './shared/services/mail.service';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {LostPasswordComponent} from './auth/lost-password/lost-password.component';
import {DeliveryAreaService} from './shared/services/delivery-area.service';
import {OrderService} from './shared/services/order.service';
import { SignupConfirmComponent } from './auth/signup-confirm/signup-confirm.component';
import { PrivacyComponent } from './privacy/privacy.component';
import {AdminHolidaysComponent} from './admin/admin-holidays/admin-holidays.component';
import {AdminHolidayListComponent} from './admin/admin-holidays/admin-holiday-list/admin-holiday-list.component';
import {AdminHolidayDetailComponent} from './admin/admin-holidays/admin-holiday-detail/admin-holiday-detail.component';
import {AdminHolidayItemComponent} from './admin/admin-holidays/admin-holiday-item/admin-holiday-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ShopComponent,
    ProductListComponent,
    ProductListItemComponent,
    ProductListToolsComponent,
    ShoppingCartComponent,
    OrderConfirmationComponent,
    CheckoutComponent,

    ProductsComponent,
    OrderAddressComponent,


    HelpComponent,
    CustomerAccountComponent,
    CustomerComponent,
    CustomerMenuComponent,
    CustomerOrdersComponent,
    ICheckDirective,
    EuroPipe,
    WeightGramPipe,
    SignupComponent,
    SigninComponent,
    AdminComponent,
    AdminProductsComponent,
    AdminProductListComponent,
    AdminProductItemComponent,
    AdminProductDetailComponent,
    AdminHolidaysComponent,
    AdminHolidayListComponent,
    AdminHolidayDetailComponent,
    AdminHolidayItemComponent,
    FooterComponent,
    OrderCheckoutComponent,
    SigninCartComponent,
    AdminUserRegComponent,
    PasswordResetComponent,
    LostPasswordComponent,
    SignupConfirmComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [
    ProductService,
    ProductCategoryService,
    ShoppingCartService,
    AuthService,
    AuthGuardService,
    AdminAuthGuardService,
    UserService,
    MailService,
    DeliveryAreaService,
    OrderService,
    HolidayService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
