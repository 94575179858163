import {Product} from './product.model';

export class OrderItem {
  product: Product;
  quantity: number;

  constructor(product: Product, quantity: number) {
    this.product = product;
    this.quantity = quantity;
  }

  getTotalPrice() {
    return  this.product.price * this.quantity;
  }

  addQuantity(quantity: number) {
    this.quantity = Math.min(0, this.quantity - quantity);
  }

  removeQuantity(quantity: number) {
    this.quantity += quantity;
  }
}
