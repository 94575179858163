import {Subject} from 'rxjs/Subject';
import {AuthService} from '../../auth/auth.service';
import {Injectable, OnInit} from '@angular/core';
import {DeliveryArea} from '../models/delivery-area.model';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import {DeliveryOption} from '../models/delivery-option.model';
import {HolidayService} from './holiday.service';

@Injectable()
export class DeliveryAreaService implements OnInit {

  deliveryAreasChanged = new Subject<DeliveryArea[]>();
  deliveryAreas: DeliveryArea[] = [];

  getDeliveryAreas() {
    return this.deliveryAreas.slice();
  }

  constructor(private authService: AuthService, private holidayService: HolidayService) {
  }

  ngOnInit(): void {
    this.loadDeliveryAreas();
  }

  loadDeliveryAreas() {
    firebase.database().ref('delivery-areas').once('value')
      .then((snapshot: DataSnapshot) => {
          this.deliveryAreas = [];
          snapshot.forEach((childSnapshot) => {
              this.deliveryAreas.push(childSnapshot.val());
              return false;
            }
          );
          this.deliveryAreasChanged.next(this.deliveryAreas.slice());
          return false;
        }
      )
      .catch(function(error) {
          console.log(error);
        }
      );
  }

  findDeliveryArea(deliveryAreaId: string) {
    for (let deliveryArea of this.deliveryAreas) {
      if (deliveryArea.id === deliveryAreaId) {
        return deliveryArea;
      }
    }
  }

  findDeliveryOptions(deliveryAreaId: string): DeliveryOption[] {
    const deliveryArea = this.findDeliveryArea(deliveryAreaId);
    if (deliveryArea === undefined) {
      return [];
    }

    let deliveryOptions: DeliveryOption[] = [];
    let loops = 0;
    while (deliveryOptions.length < 2 && loops < 3) {
      let dayOffset = loops * 7;
      // after closing time
      if (new Date().getHours() > 12) {
        dayOffset = dayOffset + 1;
      }
      if (deliveryArea.mon) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.tue) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (2 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.wed) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (3 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.thu) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (4 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.fri) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (5 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.sat) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (6 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      if (deliveryArea.sun) {
        const date = new Date();
        date.setDate(date.getDate() + 1 + dayOffset);
        date.setDate(date.getDate() + (7 + 7 - date.getDay()) % 7);
        const holiday = this.holidayService.getHolidayAt(date);
        if (!holiday) {
          deliveryOptions.push(new DeliveryOption(deliveryArea.city, date));
        }
      }
      loops += 1;
    }
    return deliveryOptions;
  }

}
