<div class="row">
  <div class="col-xs-12">
    <button class="btn btn-success" (click)="onNewHoliday()">Neue Ferien erfassen</button>
  </div>
</div>
<hr>
<div>
  <div class="row">
    <div class="col-xs-12">
      <app-admin-holiday-item
        *ngFor="let holiday of holidays; let i = index"
        [holiday]="holiday"
        [index]="i"
      ></app-admin-holiday-item>
    </div>
  </div>
</div>
