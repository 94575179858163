import {Component, Input, OnInit} from '@angular/core';
import {Holiday} from '../../../shared/models/holiday.model';

@Component({
  selector: 'app-admin-holiday-item',
  templateUrl: './admin-holiday-item.component.html',
  styleUrls: ['./admin-holiday-item.component.css']
})
export class AdminHolidayItemComponent implements OnInit {

  @Input() holiday: Holiday;
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

}
