<div id="tools">
  <div class="row">
    <div class="col-md-3 col-sm-3 col-xs-6">
      <div class="styled-select">
        <select name="sort_rating" id="sort_rating">
          <option value="" selected>Sort by ranking</option>
          <option value="lower">Lowest ranking</option>
          <option value="higher">Highest ranking</option>
        </select>
      </div>
    </div>
    <div class="col-md-9 col-sm-9 hidden-xs">
      <a href="grid_list.html" class="bt_filters"><i class="icon-th"></i></a>
    </div>
  </div>
</div><!--End tools -->
