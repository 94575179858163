import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../shared/models/product.model';

@Component({
  selector: 'app-admin-product-item',
  templateUrl: './admin-product-item.component.html',
  styleUrls: ['./admin-product-item.component.css']
})
export class AdminProductItemComponent implements OnInit {

  @Input() product: Product;
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

}
