<div class="col-md-12">
  <div class="box_style_2" id="order_process">
    <h4>Benutzerangaben</h4>
    <div class="row">
      <div class="form-group col-md-12">
        <label>Anrede</label>
        <select class="form-control" name="user_title" id="user_title">
          <option value="" selected></option>
          <option value="H">Herr</option>
          <option value="F">Frau</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label>Vorname</label>
        <input type="text" class="form-control" id="user_firstname" name="user_firstname" placeholder="Vorname">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label>Last name</label>
        <input type="text" class="form-control" id="user_lastname" name="user_lastname" placeholder="Nachname">
      </div>
    </div>


    <div class="row">
      <div class="form-group col-md-12">
        <label>Strasse + Hausnummer</label>
        <input type="text" class="form-control" id="user_street" name="user_street" placeholder="Strasse">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label>Ort</label>
        <select class="form-control" name="user_location" id="user_location">
          <option value="" selected></option>
          <option value="H">4852 Weyregg Am Attersee</option>
          <option value="F">4861 Schörfling am Attersee</option>
        </select>
      </div>
    </div>
    <hr>

    <div class="row">
      <div class="form-group col-md-12">
        <label>E-Mail</label>
        <input type="text" class="form-control" id="user_email" name="user_email" placeholder="Email">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label>Telefon / Mobil</label>
        <input type="text" class="form-control" id="user_phone" name="user_phone" placeholder="+41 79 755 76 02">
      </div>
    </div>

  </div>
</div>
