<header>
  <div class="container-fluid">
    <div class="row">
      <nav class="col-md-12 col-sm-12 col-xs-12">
        <div class="main-menu">
          <ul>
            <li><a routerLink="/admin" routerLinkActive="active" *ngIf="showAdmin()">Admin</a></li>
            <li><a routerLink="/shop" routerLinkActive="active">Reider-Auslieferung</a></li>
            <li><a routerLink="/privacy" routerLinkActive="active">Datenschutz</a></li>
            <li><a style="cursor: pointer;" (click)="onSignout()" *ngIf="isAuthenticated()">Abmelden</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>

<section class="parallax-window" id="home" data-parallax="scroll" data-image-src="assets/img/logo/logo_reider_1600x650.jpg" data-natural-width="400" data-natural-height="100" >
  <div id="subheader">
  </div>
</section>


