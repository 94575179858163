import {Component, OnDestroy, OnInit} from '@angular/core';
import {Holiday} from '../../../shared/models/holiday.model';
import {HolidayService} from '../../../shared/services/holiday.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-admin-holiday-list',
  templateUrl: './admin-holiday-list.component.html',
  styleUrls: ['./admin-holiday-list.component.css']
})
export class AdminHolidayListComponent implements OnInit, OnDestroy{

  private holidaySubscription: Subscription;

  holidays: Holiday[];
  index: number;

  constructor(private holidayService: HolidayService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.holidaySubscription = this.holidayService.holidaysChanged.subscribe(
      (holidays: Holiday[]) => {
        this.holidays = holidays;
      }
    );
    this.holidays = this.holidayService.getHolidays();
  }

  ngOnDestroy(): void {
    this.holidaySubscription.unsubscribe();
  }

  onNewHoliday() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }
}
