import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {OrderItem} from '../models/order-item.model';
import * as uuid from 'uuid';
import {DeliveryOption} from '../models/delivery-option.model';
import {AuthService} from '../../auth/auth.service';
import {User} from '../models/user.model';
import * as firebase from 'firebase';
import {MailService} from './mail.service';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class OrderService {

  orderCompleted = new Subject();
  order: Order;

  constructor(private authService: AuthService,
              private mailService: MailService) {

  }

  createNewOrder(items: OrderItem[], deliveryOption: DeliveryOption) {

    const orderItems = [];
    for (let item of items) {
      if(item.quantity > 0.001) {
        orderItems.push(item);
      }
    }

    this.order = new Order(uuid.v4(), this.authService.activeUser.userid, this.authService.activeUser.email, this.createAddressLines(this.authService.activeUser), orderItems, this.calculateTotalPrice(items), deliveryOption, 'NEW', '', new Date());
  }

  private calculateTotalPrice(items: OrderItem[]) {
    let sum = 0.0;
    for (let item of items) {
      sum += item.getTotalPrice();
    }
    return sum;
  }

  private createAddressLines(user: User) {
    let addressLines = [];
    addressLines.push(user.firstname + ' ' + user.name);
    addressLines.push(user.street);
    addressLines.push(user.zip + ' ' + user.city);
    return addressLines;
  }

  submitOrder(notes: string) {
    this.order.notes = notes;
    this.order.status = 'OPEN';
    const newOrder = firebase.database().ref('orders').push(this.order);
    newOrder.set(this.order)
      .then(
        (value) => {
          this.mailService.sendOrderConfirmation(this.authService.activeUser, this.order);
          this.orderCompleted.next();
        }
      )
      .catch(
        error => console.error(error)
      );
  }

}
