<div class="container margin_60">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="content">
        <section id="section-3">
            <div class="row">
              <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div class="box_style_2" id="signup">
                  <h3 class="nomargin_top" style="text-align: center;">Registrierung</h3>
                  <p>
                    Bitte geben Sie im folgenden Formular Ihre Anschrift und Ihre E-Mail-Adresse ein und Sie erhalten
                    schnellstmöglich von uns Ihre Zugangsdaten zum Online-Shop.
                  </p>
                  <div class="form-group">
                    <label>Vorname</label>
                    <input type="text" class="form-control" id="firstname" name="firstname"
                           placeholder="Vorname" formControlName="firstname">
                  </div>
                  <div class="form-group">
                    <label>Nachname</label>
                    <input type="text" class="form-control" id="name" name="name"
                           placeholder="Nachname" formControlName="name">
                  </div>
                  <div class="form-group">
                    <label>Telephon / Mobil</label>
                    <input type="text" id="phone" name="phone" class="form-control"
                           placeholder="Telephon / Mobil" formControlName="phone">
                  </div>
                  <div class="form-group">
                    <label>E-Mail</label>
                    <input type="email" id="email" name="email" class="form-control" placeholder="E-Mail"
                           class="form-control" formControlName="email" email>
                  </div>
                  <div class="form-group">
                    <label>Strasse</label>
                    <input type="text" id="street" name="street" class="form-control" placeholder="Strasse"
                           class="form-control" formControlName="street">
                  </div>
                  <div class="form-group">
                    <label>Ort</label>
                    <select class="form-control" name="deliveryAreaId" id="deliveryAreaId" formControlName="deliveryAreaId">
                      <option value="" selected>bitte wählen...</option>
                      <option [value]="deliveryArea.id" *ngFor="let deliveryArea of deliveryAreas">{{deliveryArea.zip}} {{deliveryArea.city}}</option>
                    </select>
                  </div>
                  <hr>
                  <button class="btn_full" type="submit" [disabled]="!userForm.valid">Abschicken</button>
                </div>
              </form>
            </div>
        </section>
      </div>
    </div>
  </div>
</div>

