import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../../../shared/models/product.model';
import {ProductService} from '../../../shared/services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-admin-product-list',
  templateUrl: './admin-product-list.component.html',
  styleUrls: ['./admin-product-list.component.css']
})
export class AdminProductListComponent implements OnInit, OnDestroy{

  private productSubscription: Subscription;

  products: Product[];
  index: number;

  constructor(private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.productSubscription = this.productService.productsChanged.subscribe(
      (products: Product[]) => {
        this.products = products;
      }
    );
    this.products = this.productService.getProducts();
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }

  onNewProduct() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }
}
