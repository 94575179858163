import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import * as firebase from 'firebase';
import {MailService} from './mail.service';

@Injectable()
export class UserService {

  constructor(private mailService: MailService) {
  }

  registerUser(user: User) {
    user.status = 'REG';
    const newUser = firebase.database().ref('users').push();
    user.id = newUser.key;
    newUser.set(user)
      .catch(
        error => console.error(error)
      );
    this.mailService.sendNewUserRegNotification(user);
  }

}
