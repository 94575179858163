import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-customer-menu',
  templateUrl: './customer-menu.component.html',
  styleUrls: ['./customer-menu.component.css']
})
export class CustomerMenuComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  navToCustomerAccount() {
    this.router.navigate(['account'], {relativeTo: this.route})
  }
  navToCustomerOrders() {
    this.router.navigate(['orders'], {relativeTo: this.route})
  }

}
