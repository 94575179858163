import {Subject} from 'rxjs/Subject';
import {Product} from '../models/product.model';
import {Http, Response} from '@angular/http';
import {AuthService} from '../../auth/auth.service';
import {Injectable} from '@angular/core';
import {OrderItem} from '../models/order-item.model';
import {Order} from '../models/order.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class ShoppingCartService {

  shoppingCartChanged = new Subject<{ items: OrderItem[], totalPrice: number }>();
  private items: OrderItem[] = [];
  private shoppingCart: Order;

  constructor(private http: Http, private authService: AuthService) {
  }

  getItems() {
    return this.items.slice();
  }

  addProduct(product: Product) {
    var isNewItem: boolean = true;
    for (let item of this.items) {
      if (item.product.id === product.id) {
        item.quantity += 1;
        isNewItem = false;
      }
    }

    if (isNewItem) {
      this.items.push(new OrderItem(product, 1));
    }

    this.shoppingCartChanged.next({items: this.items.slice(), totalPrice: this.getTotalPrice()});
  }

  getTotalPrice() {
    var sum = 0.0;
    for (let item of this.items) {
      sum += item.getTotalPrice();
    }
    return sum;
  }

  // loadShoppingCart() {
  //   //const token = this.authService.getToken();
  //   this.http.get('https://reider-dev.firebaseio.com/shopping-carts.json?')
  //     .map(
  //       (response: Response) => {
  //         this.shoppingCart = response.json();
  //       }
  //     )
  //     .subscribe(
  //       (products: Product[]) => {
  //         this.products = products;
  //         if (!this.products) {
  //           this.products = [];
  //         }
  //         this.productsChanged.next(this.products.slice());
  //       }
  //     );
  // }

  saveShoppingCart() {
    //const token = this.authService.getToken();
    this.http.put('https://' + environment.firebase.projectId + '.firebaseio.com/shopping-carts.json', this.shoppingCart)
      .map(
        (response: Response) => {
        }
      );
  }

  onAddItem(index: number) {
    if (this.items[index]) {
      this.items[index].quantity += 1;
    }
    this.shoppingCartChanged.next({items: this.items.slice(), totalPrice: this.getTotalPrice()});
  }

  onRemoveItem(index: number) {
    if (this.items[index] && this.items[index].quantity > 0) {
      this.items[index].quantity -= 1;
    }
    this.shoppingCartChanged.next({items: this.items.slice(), totalPrice: this.getTotalPrice()});
  }

  canCheckout(): boolean {
    return this.getTotalPrice() >= 5.0 && this.getTotalPrice() <= 100.0;
  }

  resetCart() {
    this.items = [];
    this.shoppingCartChanged.next({items: this.items.slice(), totalPrice: this.getTotalPrice()});
  }
}
