import {Subject} from 'rxjs/Subject';
import {Product} from '../models/product.model';
import {Headers, Http, Response} from '@angular/http';
import {AuthService} from '../../auth/auth.service';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import DataSnapshot = firebase.database.DataSnapshot;
import {environment} from '../../../environments/environment';

@Injectable()
export class ProductService {

  productsChanged = new Subject<Product[]>();
  products: Product[] = [];

  constructor(private http: Http, private authService: AuthService) {
  }

  getProducts() {
    return this.products.slice();
  }

  getProduct(id: string) {
    for (let product of this.products) {
      if (product.id === id) {
        return product;
      }
    }
  }

  loadProducts() {
    firebase.database().ref('products').orderByChild('visible').equalTo(true).once('value')
      .then((snapshot: DataSnapshot) => {
        this.products = [];
        snapshot.forEach((childSnapshot) => {
            this.products.push(childSnapshot.val());
            return false;
          }
        );
        this.productsChanged.next(this.products.slice());
      });
  }

  persistProducts() {
    //const token = this.authService.getToken();
    const headers = new Headers({'Content-Type': 'application/json'});
    const token = this.authService.getToken();
    return this.http.put('https://' + environment.firebase.projectId + '.firebaseio.com/products.json?auth=' + token, this.products, {headers: headers})
      .subscribe(
        (response) => {
          this.productsChanged.next(this.products.slice());
        },
        (error) => console.log(error)
      );
  }

  addProduct(product: Product) {
    this.products.push(product);
    this.persistProducts();
    this.productsChanged.next(this.products.slice());
  }

  updateProduct(id: string, newProduct: Product) {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        this.products[i] = newProduct;
      }
    }
    this.persistProducts();
    this.productsChanged.next(this.products.slice());
  }

  deleteProduct(id: string) {
    let removeIndex;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].id === id) {
        removeIndex = i;
      }
    }
    if (removeIndex) {
      this.products.splice(removeIndex, 1);
    }
    this.productsChanged.next(this.products.slice());
  }

}
