import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShopComponent} from './shop/shop.component';

import {ProductsComponent} from './shop/products/products.component';
import {CheckoutComponent} from './shop/checkout/checkout.component';
import {CustomerComponent} from './customer/customer.component';
import {CustomerAccountComponent} from './customer/customer-account/customer-account.component';
import {CustomerOrdersComponent} from './customer/customer-orders/customer-orders.component';
import {SignupComponent} from './auth/signup/signup.component';
import {SigninComponent} from './auth/signin/signin.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {AdminComponent} from './admin/admin.component';
import {AdminProductsComponent} from './admin/admin-products/admin-products.component';
import {AdminProductDetailComponent} from './admin/admin-products/admin-product-detail/admin-product-detail.component';
import {AdminUserRegComponent} from './admin/admin-user-reg/admin-user-reg.component';
import {AdminHolidaysComponent} from './admin/admin-holidays/admin-holidays.component';
import {AdminHolidayDetailComponent} from './admin/admin-holidays/admin-holiday-detail/admin-holiday-detail.component';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {LostPasswordComponent} from './auth/lost-password/lost-password.component';
import {OrderConfirmationComponent} from './shop/checkout/order-confirmation/order-confirmation.component';
import {SignupConfirmComponent} from './auth/signup-confirm/signup-confirm.component';
import {PrivacyComponent} from './privacy/privacy.component';

const appRoutes: Routes = [
  {path: '', redirectTo: '/shop', pathMatch: 'full'},
  {
    path: 'shop', component: ShopComponent, children: [
      {path: '', component: ProductsComponent, pathMatch: 'full'},
      {path: 'products', component: ProductsComponent},
      {path: 'checkout', component: CheckoutComponent},
      {path: 'order-confirmation', component: OrderConfirmationComponent},
    ]
  },
  {
    path: 'customer', component: CustomerComponent, canActivate: [AuthGuardService]
    , children: [
      {path: '', component: CustomerAccountComponent},
      {path: 'account', component: CustomerAccountComponent},
      {path: 'orders', component: CustomerOrdersComponent}
    ]
  },
  {path: 'password-reset', component: PasswordResetComponent},
  {path: 'signup', component: SignupComponent, pathMatch: 'full'},
  {path: 'signup/confirm', component: SignupConfirmComponent, pathMatch: 'full'},
  {path: 'signin', component: SigninComponent},
  {path: 'lost-password', component: LostPasswordComponent},
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuardService], children: [
      {path: '', redirectTo: '/admin/user-regs', pathMatch: 'full'},
      { path: 'products', component: AdminProductsComponent, children: [
          {path: 'new', component: AdminProductDetailComponent},
          {path: ':id', component: AdminProductDetailComponent}
        ]
      },
      {path: 'user-regs', component: AdminUserRegComponent},
      { path: 'holidays', component: AdminHolidaysComponent, children: [
          {path: 'new', component: AdminHolidayDetailComponent},
          {path: ':id', component: AdminHolidayDetailComponent}
        ]
      },
    ]
  },

  {path: 'privacy', component: PrivacyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
