import {Directive, ElementRef} from '@angular/core';

declare var $: any;

@Directive({
  selector: '[icheck]'
})

export class ICheckDirective {
  $: any = $;
  constructor(el: ElementRef) {
    this.$(el.nativeElement).iCheck({
      checkboxClass: 'icheckbox_square-grey',
      radioClass: 'iradio_square-grey'
    })
  }
}
