import {Component, Input, OnInit} from '@angular/core';
import {ShoppingCartService} from '../../shared/services/shopping-cart.service';
import {Product} from '../../shared/models/product.model';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.css']
})
export class ProductListItemComponent implements OnInit {

  @Input() product: Product;

  constructor(private authService: AuthService, private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
  }

  onAddToCart() {
    this.shoppingCartService.addProduct(this.product);
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

}
