import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../shared/services/order.service';
import {Order} from '../../../shared/models/order.model';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-order-checkout',
  templateUrl: './order-checkout.component.html',
  styleUrls: ['./order-checkout.component.css']
})
export class OrderCheckoutComponent implements OnInit {

  order: Order;

  @ViewChild('f') orderForm: NgForm;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.order = this.orderService.order;
    if (this.order === undefined) {
      this.router.navigate(['/']);
    }
  }

  onSubmit() {
    this.orderService.submitOrder(this.orderForm.value.notes);
    this.orderForm.resetForm();
    this.router.navigate(['../order-confirmation'], {relativeTo: this.route});
  }

  canCheckout() {
    return this.order.items.length > 0 &&  this.order.totalPrice >= 5.0 &&  this.order.totalPrice <= 100.0;
  }

}
