<form (ngSubmit)="onSubmit()" #f="ngForm">
  <div class="box_style_2" id="order_process">
    <h2 class="inner">Bestellübersicht</h2>
    <div class="row">
      <div class="col-md-12">
        <h4>Produkte</h4>
        <table class="table table-striped nomargin">
          <tbody>
          <tr *ngFor="let item of order.items">
            <td>
              <strong>{{item.quantity}}x</strong> {{item.product.name}}
            </td>
            <td>
              <strong class="pull-right">{{item.getTotalPrice() | euro}}</strong>
            </td>
          </tr>
          <tr>
            <td class="total_confirm">
              TOTAL
            </td>
            <td class="total_confirm">
              <span class="pull-right">{{order.totalPrice | euro}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <h4>Lieferdatum</h4>
        {{order.deliveryOption.deliveryArea}}, <strong>{{order.deliveryOption.date | date:'dd.MM.yyyy'}}</strong>
        <br/><br/>
        <p>Geliefert wird im Verlaufe des Vormittags bis ca. 14:00 Uhr!</p>
      </div>
      <div class="col-xs-12 col-md-6">
        <h4>Lieferadresse</h4>
        <ul class="order_delivery_address">
          <li *ngFor="let addressLine of order.addressLines">{{addressLine}}</li>
        </ul>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-12">
        <h4>Notiz</h4>
        <textarea class="form-control" style="height:60px" placeholder="" name="notes" id="notes" ngModel></textarea>
      </div>
    </div>
    <hr>
    <button class="btn_full" type="submit" *ngIf="canCheckout()">Bestellung abschicken
    </button>
  </div>
</form>
